body{
  margin: 0;
  padding: 0;
}
*{
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p
{
  margin: 0;
}
.App{
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #F5F7FB;
}
/*Styling the first page*/
.first--page{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}
.first--page h1{
  font-family: 'Karla';
font-style: normal;
font-weight: 700;
font-size: 31.25px;
line-height: 37px;
text-align: center;

color: #293264;

}
.first--page p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: center;
color: #293264;

}
.first--page button{
  position: relative;
  margin-top: 1em;
  padding: 0.5em 1.5em;
  border: none;
  background: #4D5B9E;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}
.top-right-effect{
  width: 297px;
  height: 100px;
  position: absolute;
  left: 80.24%;
  right: -62.24%;
  top: 0;
  bottom: -161.16%;
background: #FFFAD1;
transform: rotate(230.49deg);
}
.bottom-left-effect{
  border-radius: 50%;
position: absolute;
height: 100px;
width: 150px;
left: -6%;
right: 0%;
/* top: 0%; */
bottom: 0;
background: #DEEBF8;
transform: rotate(50deg);
margin: 0;
}
/*Styling the question page*/
.options{
  display: flex;
  align-items: left;
  justify-content: left;
  gap: 20px;
  margin-bottom: 0.5em;
  padding-bottom: 1em;
  border-bottom: 1px solid black;
}
label{
 background-color: none;
  color: black;
  font-size: small;
  border: 1px solid black;
  border-radius:5px ;
  padding: 0.1em 0.4em;
  cursor: pointer;
}
input[type="radio"]{
  display: none;
}
.selected{
  background-color: red;
}
.tranparent{
  background-color: transparent;
}
input[type="radio"]:checked+label{
  background-color: #D6DBF5;;
}
.container{
  text-align: center;
  max-width: 900px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 0 auto;
}
h5{
  margin-bottom: 0.5em;
  text-align: left;
}
button{
  display: inline-block;
  width: 120px;
  margin: 1em auto;
  background-color: #293264;
  border: none;
  padding:0.5em 0.2em;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
label{
  width: max-content;
  font-size: 12px;
}
